import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SEO } from 'components';

function PollDocumentation() {
  const { theme, selectedPollDocumentation, setSelectedPollDocumentation } =
    useContext(LocalContext);
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(src/data/docs)/" } }
        sort: { fields: id }
      ) {
        edges {
          node {
            frontmatter {
              id
              name
              figures {
                id
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
                sections {
                  sectionText
                  sectionTitle
                  sectionVideo
                  sectionImage
                  sid
                }
                text
                title
                type
                video
              }
            }
          }
        }
      }
    }
  `);

  const generalPollData = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.frontmatter.id === 1
  ).node.frontmatter;
  const feedbackPollData = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.frontmatter.id === 2
  ).node.frontmatter;
  const wordcloudPollData = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.frontmatter.id === 3
  ).node.frontmatter;

  return (
    <>
      <SEO pageSpecificTitle="Help Hub | Polls Documentation" />
      <MenuTab
        theme={theme}
        initial={{ y: -120 }}
        animate={{ y: 0 }}
        exit={{ y: -120 }}
        transition={{ delay: 0.75, duration: 0.75 }}>
        <MenuTitle
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          <motion.button type="button" onClick={() => navigate(-1)}>
            <span>Help Hub</span>
          </motion.button>
          <span style={{ fontWeight: '400', paddingBottom: '0.125rem' }}>{'>'}</span>
          <span>Polls</span>
        </MenuTitle>
        <Buttons
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          <Button
            type="button"
            onClick={() => setSelectedPollDocumentation('general')}
            active={selectedPollDocumentation === 'general'}
            theme={theme}>
            General Poll
          </Button>
          <Button
            type="button"
            onClick={() => setSelectedPollDocumentation('feedback')}
            active={selectedPollDocumentation === 'feedback'}
            theme={theme}>
            Feedback Poll
          </Button>
        </Buttons>
      </MenuTab>
      <Wrapper
        theme={theme}
        key={selectedPollDocumentation}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5, duration: 1 }}>
        <Content>
          <h2>Polls Guidelines</h2>
          <h3>
            As a moderator you have access to the Moderator&apos;s Control Panel from within the
            Polls feature of the livestream event. Here you can create, edit and open polls that
            encourage participation from viewers. You can then view or share the results with
            participants.
          </h3>
          {selectedPollDocumentation === 'general' && (
            <FigureWrapper>
              {generalPollData.figures.map((figure) => (
                <>
                  {figure.title && <h4>{figure.title}</h4>}
                  <Figure>
                    {figure.image && (
                      <GatsbyImage
                        image={getImage(figure.image)}
                        alt="Poll Guideline"
                        style={{ width: '100%', height: '100%' }}
                      />
                    )}
                    {figure.video && (
                      <iframe
                        title="vimeo-player"
                        src={figure.video}
                        width="100%"
                        height="260"
                        frameBorder="0"
                        allow="autoplay"
                      />
                    )}
                    {figure.sections &&
                      figure.sections.map((section) => (
                        <Option>
                          <ImageCaption dangerouslySetInnerHTML={{ __html: section.sectionText }} />
                          {section.sectionVideo && (
                            <iframe
                              title="vimeo-player"
                              src={section.sectionVideo}
                              height="340"
                              frameBorder="0"
                              allow="autoplay"
                            />
                          )}
                          {section.sectionImage && (
                            <GatsbyImage
                              image={getImage(section.sectionImage)}
                              alt="Poll Guideline"
                              style={{ width: '100%', height: '100%', maxWidth: '18.75rem' }}
                            />
                          )}
                        </Option>
                      ))}
                    {figure.text && (
                      <ImageCaption dangerouslySetInnerHTML={{ __html: figure.text }} />
                    )}
                  </Figure>
                </>
              ))}
            </FigureWrapper>
          )}
          {selectedPollDocumentation === 'feedback' && (
            <FigureWrapper>
              {feedbackPollData.figures.map((figure) => (
                <>
                  {figure.title && <h4>{figure.title}</h4>}
                  <Figure>
                    {figure.image && (
                      <GatsbyImage
                        image={getImage(figure.image)}
                        alt="Poll Guideline"
                        style={{ width: '100%', height: '100%' }}
                      />
                    )}
                    {figure.video && (
                      <iframe
                        title="vimeo-player"
                        src={figure.video}
                        width="100%"
                        height="260"
                        frameBorder="0"
                        allow="autoplay"
                      />
                    )}
                    {figure.sections &&
                      figure.sections.map((section) => (
                        <Option>
                          <ImageCaption dangerouslySetInnerHTML={{ __html: section.sectionText }} />
                          {section.sectionVideo && (
                            <iframe
                              title="vimeo-player"
                              src={section.sectionVideo}
                              height="340"
                              frameBorder="0"
                              allow="autoplay"
                            />
                          )}
                          {section.sectionImage && (
                            <GatsbyImage
                              image={getImage(section.sectionImage)}
                              alt="Poll Guideline"
                              style={{ width: '100%', height: '100%', maxWidth: '18.75rem' }}
                            />
                          )}
                        </Option>
                      ))}
                    {figure.text && (
                      <ImageCaption dangerouslySetInnerHTML={{ __html: figure.text }} />
                    )}
                  </Figure>
                </>
              ))}
            </FigureWrapper>
          )}
          {selectedPollDocumentation === 'wordcloud' && (
            <FigureWrapper>
              {wordcloudPollData.figures.map((figure) => (
                <>
                  {figure.title && <h4>{figure.title}</h4>}
                  <Figure>
                    {figure.image && (
                      <GatsbyImage
                        image={getImage(figure.image)}
                        alt="Poll Guideline"
                        style={{ width: '100%', height: '100%' }}
                      />
                    )}
                    {figure.video && (
                      <iframe
                        title="vimeo-player"
                        src={figure.video}
                        width="100%"
                        height="260"
                        frameBorder="0"
                        allow="autoplay"
                      />
                    )}
                    {figure.sections &&
                      figure.sections.map((section) => (
                        <Option>
                          <ImageCaption dangerouslySetInnerHTML={{ __html: section.sectionText }} />
                          {section.sectionVideo && (
                            <iframe
                              title="vimeo-player"
                              src={section.sectionVideo}
                              height="340"
                              frameBorder="0"
                              allow="autoplay"
                            />
                          )}
                          {section.sectionImage && (
                            <GatsbyImage
                              image={getImage(section.sectionImage)}
                              alt="Poll Guideline"
                              style={{ width: '100%', height: '100%', maxWidth: '18.75rem' }}
                            />
                          )}
                        </Option>
                      ))}
                    {figure.text && (
                      <ImageCaption dangerouslySetInnerHTML={{ __html: figure.text }} />
                    )}
                  </Figure>
                </>
              ))}
            </FigureWrapper>
          )}
        </Content>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-column: 1 / 7;
  justify-content: center;
  margin: 9rem 0 2rem 0;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 1 / 13;
    margin: 10rem 0 4rem 0;
  }
`;

const MenuTab = styled(motion.div)`
  align-items: center;
  background: ${({ theme }) => theme.muted};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  max-height: 60px;
  min-height: 60px;
  position: absolute;
  top: 3.75rem;
  width: 100%;
  span {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0 1rem;
  }
  button {
    background: transparent;
    cursor: pointer;
    span {
      font-weight: 400;
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    span {
      padding: 0;
    }
  }
`;

const MenuTitle = styled(motion.div)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  grid-column: 1 / 6;
  @media only screen and (min-width: 1150px) {
    grid-column: 2 / 5;
  }
`;

const Button = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.contrastTextColor};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  ${(props) =>
    props.active
      ? css`
          &:before {
            background: #ffffff;
            bottom: -2px;
            content: '';
            height: 2px;
            left: 0;
            position: absolute;
            transition: width 100ms ease-in-out;
            width: 100%;
          }
        `
      : css`
          &:before {
            background: #ffffff;
            bottom: -2px;
            content: '';
            height: 2px;
            left: 0;
            position: absolute;
            transition: width 100ms ease-in-out;
            width: 0;
          }
        `}
  &:hover {
    &:before {
      width: 100%;
    }
  }
`;

const Buttons = styled(motion.div)`
  display: none;
  @media only screen and (min-width: 1150px) {
    align-items: center;
    display: flex;
    gap: 3rem;
    grid-column: 6 / 11;
    justify-content: end;
  }
`;

const Content = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  h2 {
    margin: 1rem;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    width: 50%;
  }
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding: 0 1rem;
  width: 100%;
  @media only screen and (max-width: 520px) {
    flex-direction: column;
  }
`;

const ImageCaption = styled.figcaption`
  padding: 1rem 0 2rem 0;
  width: 100%;
  small {
    opacity: 70%;
  }
`;

const Figure = styled.figure`
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 60vw;
  @media only screen and (max-width: 1024px) {
    width: 90vw;
  }
`;

const FigureWrapper = styled.div`
  margin: 3rem;
  figure {
    margin: 2rem 0;
  }
`;

export default PollDocumentation;
